import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
const Corsi = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(
          "https://apis.stefanocoggiola.com/corsi"
        );
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        setData(actualData);
        setError(null);
      } catch(err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }  
    }
    getData()
  }, [])

  return (
    <div className="App">
      <h1>Learning Courses extracted via API from MongoDB</h1>
      {loading && <div>A moment please...</div>}
      {error && (
        <div>{`There is a problem fetching the post data - ${error}`}</div>
      )}
      <div className="row">
        {data &&
          data.map(({_id, title, description, credit, price, image }) => (
            <div className="col-sm-4 col-md-3 cardProdotto" key={_id}>
              <h3><Link to={`/corso/${_id}`}>{title}</Link>
              </h3><Link to={`/corso/${_id}`}>
              {image ? (
                <img src={image} className="fotoCorsi" alt={title} />
              ) : (
                <img src="/empty-img.jpg" className="fotoCorsi" alt={title} />
              )}</Link>
              <br />
              By <b>{credit}</b>
              <div dangerouslySetInnerHTML={{__html: description}}/>
              <h2>{price.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</h2>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Corsi