import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import "./App.css";

import Corsi from './components/corsi';
import Corso from './components/corso';

const App = () => {    
  return (
  <>
   <Router>       
      <Routes>
        <Route exact path={`/corso/:id`} element={<Corso/>} />
        <Route path='/' element={<Corsi/>} />
      </Routes>
   </Router>
   </>
  );
}

export default App;