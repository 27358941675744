import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";

const Corso = () => {
  
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    const getData = async (req,res) => {
      try {
        const response = await fetch(
          `https://apis.stefanocoggiola.com/corsi/${id}`
        );
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        setData(actualData);
        setError(null);
      } catch(err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }  
    }
    getData()
  }, [id])
  return (
    <div className="App">
        <p>&nbsp;</p>
      {loading && <div>A moment please...</div>}
      {error && (
        <div>{`There is a problem fetching the post data - ${error}`}</div>
      )}
      <div class="row">
        <div class="col-sm-12 right">
          <Link to="/"><i className="fa-solid fa-arrow-left-long"></i> &nbsp; Go back</Link>
        </div>
      </div>
      {data &&
        <div className="col-sm-11 col-md-11 cardProdottoDett">
          <h1>{data.title}</h1>      
          <div className="row">
            
            <div className='copertina'>
              {data.image ? (
                <img src={data.image} className="fotoCorso" alt={data.title} />
              ) : (
                <img src="/empty-img.jpg" className="fotoCorso" alt={data.title} />
              )}
            </div>
              <h5>Description</h5>
              <div dangerouslySetInnerHTML={{__html: data.description}} className='dettaglio' />            
              <h3><hr /> Program:</h3>
              <div dangerouslySetInnerHTML={{__html: data.longTxt}} className='dettaglio' />
              <h2 className='prezzo'>€ {data.price.toFixed(2)}</h2>
            </div>
          </div>
      }
      <div className="row">
          <div className="col-md-12"><Link to="/"><i class="fa-solid fa-arrow-left-long"></i> Courses list</Link></div>
      </div>
    </div>
  );
}

export default Corso